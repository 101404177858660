import type { AxiosInstance } from 'axios'
import type { GetOrganisationUsersResponse } from '~/types/apiResponse/users.response'

export const users = (axiosInstance: AxiosInstance) => {
  return {
    getOrganisationUsers(organisationId: string) {
      return axiosInstance.get<GetOrganisationUsersResponse>(
        `/users/${organisationId}`,
      )
    },
  }
}
