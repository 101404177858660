import type { Axios } from 'axios'

export function connections(axiosInstance: Axios) {
  return {
    exchangeAuthCodeForAccessToken(payload: {
      organisationId: string
      code?: string
    }) {
      const { organisationId, code } = payload
      return axiosInstance.post(`/stripe/connections/${organisationId}`, null, {
        params: { code },
      })
    },
  }
}
