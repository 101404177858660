import type { AxiosInstance } from 'axios'
import type { ValidateBankAccountResponse } from '~/types/apiResponse/bankAccountValidations.response'

export const bankAccountValidations = (axiosInstance: AxiosInstance) => {
  return {
    validateBankAccount(sortCode: string, accountNumber: string) {
      return axiosInstance.post<ValidateBankAccountResponse>(
        '/bankAccountValidations',
        null,
        {
          params: {
            sortCode,
            accountNumber,
          },
        },
      )
    },
  }
}
