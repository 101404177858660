import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { useAuthStore } from '~/stores/auth'

export default defineNuxtPlugin({
  name: 'bugsnag-plugin',
  setup(nuxtApp) {
    const config = nuxtApp.$config
    Bugsnag.start({
      apiKey: config.public.BUGSNAG,
      plugins: [new BugsnagPluginVue()],
      enabledReleaseStages: ['production'],
      // appVersion: '1.0.0',
      onError: function (event) {
        const { profile, currentlySelectedOrganisation } =
          storeToRefs(useProfileStore())
        const { personId } = storeToRefs(useAuthStore())
        if (!personId.value || !profile.value) return

        const { family_name, given_name, work_email_address } = profile.value
        event.setUser(
          personId.value,
          work_email_address,
          `${given_name} ${family_name}`,
        )

        if (currentlySelectedOrganisation.value) {
          event.addMetadata('organisation', {
            organisation: currentlySelectedOrganisation.value,
          })
        }
      },
    })
    const bugsnagVue = Bugsnag.getPlugin('vue')!
    nuxtApp.vueApp.use(bugsnagVue)
    return {
      provide: {
        bugsnag: Bugsnag,
      },
    }
  },
})
