import type { AxiosInstance } from 'axios'
import type { GetOrganisationAccountBalanceResponse } from '~/types/apiResponse/balances.response'

export const balances = (axiosInstance: AxiosInstance) => {
  return {
    getOrganisationAccountBalance(organisationId: string) {
      return axiosInstance.get<GetOrganisationAccountBalanceResponse>(
        `Balances/${organisationId}`,
      )
    },
  }
}
