import Cleave from 'cleave.js'
import type { CleaveOptions } from 'cleave.js/options'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive<any, CleaveOptions>('cleave', {
    mounted(el, binding) {
      el.cleave = new Cleave(el, binding.value || {})
    },
    updated(el) {
      const event = new Event('cleave', { bubbles: false })
      setTimeout(() => {
        el.value = (el.cleave as Cleave).properties.result
        el.dispatchEvent(event)
      }, 50)
    },
  })
})
