import type { AxiosInstance } from 'axios'
import type { GenerateFXQuotePayload } from '~/types/apiPayload/fx.payload'
import type { GetFXQuoteResponse } from '~/types/apiResponse/fx.payment'

export const fx = (axiosInstance: AxiosInstance) => {
  return {
    generateFXQuote(payload: GenerateFXQuotePayload) {
      return axiosInstance.post<GetFXQuoteResponse>('/fx', { ...payload })
    },
  }
}
