import type { AxiosInstance } from 'axios'
import type { GetOrganisationProductsResponse } from '~/types/apiResponse/products.response'

export const products = (axiosInstance: AxiosInstance) => {
  return {
    getOrganisationProducts(personId: string, organisationId: string) {
      return axiosInstance.get<GetOrganisationProductsResponse>(
        '/products/organisation',
        {
          params: {
            PersonId: personId,
            OrganisationId: organisationId,
          },
        },
      )
    },
  }
}
