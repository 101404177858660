import type { AxiosInstance } from 'axios'
import type {
  GetOrganisationConfigurationResponse,
  SettingPaymentAccountType,
} from '~/types/apiResponse/settings.response'

export const settings = (axiosInstance: AxiosInstance) => {
  return {
    getOrganisationConfiguration(organisationId: string) {
      return axiosInstance.get<GetOrganisationConfigurationResponse>(
        `/Settings/${organisationId}`,
      )
    },

    getOrganisationPaymentAccounts(organisationId: string) {
      return axiosInstance.get<SettingPaymentAccountType[]>(
        `/Settings/${organisationId}/accounting/payment-accounts`,
      )
    },

    updateOrganisationConfiguration(
      organisationId: string,
      payload: GetOrganisationConfigurationResponse,
    ) {
      return axiosInstance.put<SettingPaymentAccountType[]>(
        `/Settings/${organisationId}`,
        { ...payload },
      )
    },
  }
}
