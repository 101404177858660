import type { AxiosInstance } from 'axios'
import type {
  ExportCreditFacilityAsCSVParams,
  ExportRepaymentsPayload,
  GetHistoricalRepaymentsPayload,
  GetUpcomingRepaymentsPayload,
} from '~/types/apiPayload/repayments.payload'
import type {
  GetHistoricalRepaymentsResponse,
  GetOrganizationRepaymentsSummaryResponse,
  GetUpcomingRepaymentsResponse,
} from '~/types/apiResponse/repayments.response'

export const repayments = (axiosInstance: AxiosInstance) => {
  return {
    exportCreditFacilityAsCSV(exportOptions: {
      organisationId: string
      params?: ExportCreditFacilityAsCSVParams
    }) {
      const { organisationId, params } = exportOptions
      return axiosInstance.post(`/repayments/exports/${organisationId}`, null, {
        params: { ...params },
      })
    },
    getOrganizationRepaymentsSummary(organisationId: string) {
      return axiosInstance.get<GetOrganizationRepaymentsSummaryResponse>(
        `/repayments/summary/${organisationId}`,
      )
    },
    getUpcomingRepayments(payload: GetUpcomingRepaymentsPayload) {
      const { organisationId, ...rest } = payload
      return axiosInstance.get<GetUpcomingRepaymentsResponse>(
        `/repayments/v2/Upcoming/${organisationId}`,
        {
          params: { rest },
        },
      )
    },

    getHistoricalRepayments(payload: GetHistoricalRepaymentsPayload) {
      const { organisationId } = payload
      return axiosInstance.get<GetHistoricalRepaymentsResponse>(
        `/repayments/v2/Historic/${organisationId}`,
      )
    },

    // exportHistoricalRepayments(payload: ExportHistoricalRepaymentsPayload) {
    //   const { organisationId, ...rest } = payload
    //   return axiosInstance.get(
    //     `/Repayments/repayment-history/${organisationId}/export`,
    //     { params: { ...rest } },
    //   )
    // },

    // exportUpcomingRepayments(payload: ExportHistoricalRepaymentsPayload) {
    //   const { organisationId, ...rest } = payload
    //   return axiosInstance.get(
    //     `/Repayments/upcoming-repayment/${organisationId}/export`,
    //     { params: { ...rest } },
    //   )
    // },

    exportRepayments(payload: ExportRepaymentsPayload) {
      return axiosInstance.post('/repayments/repayments-export', payload)
    },
  }
}
