// /services/auth-service.ts
import {
  User,
  UserManager,
  WebStorageStateStore,
  type SigninRedirectArgs,
} from 'oidc-client-ts'

export default class AuthService {
  userManager: UserManager

  constructor() {
    this.userManager = new UserManager({
      authority: useRuntimeConfig().public.AUTH_URL || '',
      client_id: 'web-app',
      redirect_uri: `${window.location.origin}/callback`,
      silent_redirect_uri: `${window.location.origin}/silent-refresh`,
      scope: 'openid profile mca banking',
      response_type: 'code',
      post_logout_redirect_uri: window.location.origin,
      userStore: new WebStorageStateStore(),
      loadUserInfo: true,
    })
  }

  public signInRedirect(options?: SigninRedirectArgs) {
    return this.userManager.signinRedirect(options)
  }

  public signInCallback() {
    return this.userManager.signinCallback()
  }

  public renewToken(): Promise<void> {
    return this.userManager.signinSilentCallback()
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect()
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser()
  }

  public async getAccessToken(): Promise<string | null> {
    const user = await this.userManager.getUser()
    return user ? user.access_token : null
  }
}

export const useAuthService = () => {
  return new AuthService()
}
