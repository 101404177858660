import type { AxiosInstance } from 'axios'
import type { GetCardPaymentFeesResponse } from '~/types/apiResponse/cardPayments.response'

export const cardPayments = (axiosInstance: AxiosInstance) => {
  return {
    deleteCardPaymentMethod(
      organisationId: string,
      cardPaymentMethodId: string,
    ) {
      return axiosInstance.delete(
        `/CardPayments/${organisationId}/CardPaymentMethods/${cardPaymentMethodId}`,
      )
    },

    getCardPaymentFees() {
      return axiosInstance.get<GetCardPaymentFeesResponse>('/CardPayments/Fee')
    },
  }
}
