import type { AxiosInstance } from 'axios'
import type { ConfirmPayeeDetailsPayload } from '~/types/apiPayload/confirmationOfPayee.payload'
import type { ConfirmPayeeDetailsResponse } from '~/types/apiResponse/confirmationOfPayee.response'

export const confirmationOfPayee = (axiosInstance: AxiosInstance) => {
  return {
    confirmPayeeDetails(payload: ConfirmPayeeDetailsPayload) {
      const { organisationId, ...rest } = payload
      return axiosInstance.post<ConfirmPayeeDetailsResponse>(
        `/confirmationOfPayee/${organisationId}`,
        rest,
      )
    },
  }
}
