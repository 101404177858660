import type { AxiosInstance } from 'axios'
import type {
  CreateGoCardLessMandateAuthorisationResponse,
  GetPaymentMandateStatusResponse,
} from '~/types/apiResponse/payments.response'

export const payments = (axiosInstance: AxiosInstance) => {
  return {
    getPaymentMandateStatus(orgId: string) {
      return axiosInstance.get<GetPaymentMandateStatusResponse>(
        '/Payments/mandate/status',
        {
          params: { orgId },
        },
      )
    },
    createGoCardLessMandateAuthorisation(organisation_id: string) {
      return axiosInstance.post<CreateGoCardLessMandateAuthorisationResponse>(
        '/Payments/mandate/authorise',
        {
          organisation_id,
        },
      )
    },
  }
}
