export const routes = {
  home: '/',
  dashboard: '/dashboard',
  bills: '/bills',
  newBill: '/bills/new',
  repayments: '/repayments',
  bankConnectionsSettings: '/settings/connections?active=bank-accounts',
  accountingConnectionsSettings: '/settings/connections?active=accounting',
  ecommerceConnectionsSettings: '/settings/connections?active=ecommerce',
  paymentsConnectionsSettings: '/settings/connections?active=payments',
  logout: '/logout',
  quickBooksDisconnectedPage: '/callback/quickbooks-disconnected',
  onboarding: '/onboarding',
}
