import type { AxiosInstance } from 'axios'
import type {
  AddPermissionsPayload,
  RemovePermissionsPayload,
} from '~/types/apiPayload/permissions.payload'

export const permissions = (axiosInstance: AxiosInstance) => {
  return {
    getAllPermissions() {
      return axiosInstance.get('/permissions')
    },
    addPermissions(payload: AddPermissionsPayload) {
      const { organisation_id, ...rest } = payload
      return axiosInstance.post(`/permissions/${organisation_id}/assign`, rest)
    },
    removePermissions(payload: RemovePermissionsPayload) {
      const { organisation_id, ...rest } = payload
      return axiosInstance.post(`/permissions/${organisation_id}/remove`, rest)
    },
  }
}
