import type { AxiosInstance } from 'axios'
import type { StartNewOnboardingWorkFlowPayload } from '~/types/apiPayload/onboarding.payload'
import type { StartNewOnboardingWorkFlowResponse } from '~/types/apiResponse/onboarding.response'

export const onboarding = (axiosInstance: AxiosInstance) => {
  return {
    startNewOnboardingWorkFlow(payload: StartNewOnboardingWorkFlowPayload) {
      return axiosInstance.post<StartNewOnboardingWorkFlowResponse>(`/start`, {
        ...payload,
      })
    },
  }
}
