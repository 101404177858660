import type { AxiosInstance, AxiosProgressEvent } from 'axios'

export const fileUpload = (axiosInstance: AxiosInstance) => {
  return {
    uploadFile(payload: {
      organisationId: string
      uploadType: string
      files: File[]
      uploadProgessFn?: (event: AxiosProgressEvent) => boolean
    }) {
      const { organisationId, uploadType, files, uploadProgessFn } = payload

      const formData = new FormData()
      files.forEach((file) => {
        formData.append('files', file)
      })

      return axiosInstance.post(
        `/fileUpload/organisation/${organisationId}/upload/${uploadType}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: uploadProgessFn,
        },
      )
    },
    downloadFile(filePath: string) {
      return axiosInstance.get('/fileUpload/downloadFile', {
        params: { filePath },
        responseType: 'blob',
      })
    },
  }
}
