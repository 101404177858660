import type { AxiosInstance } from 'axios'
import type { AcceptOrDeclineCreditFacilityUpdateRequestPayload } from '~/types/apiPayload/creditFacility.payload'
import type {
  GetBorrowerCreditFacilityResponse,
  GetCreditFacilityFundRequestResponse,
} from '~/types/apiResponse/creditFacility.response'

export const creditFacilities = (axiosInstance: AxiosInstance) => {
  return {
    getBorrowerCreditFacility(borrowerId: string) {
      return axiosInstance.get<GetBorrowerCreditFacilityResponse>(
        `/CreditFacilities/${borrowerId}`,
      )
    },

    getCreditFacilityFundRequest(borrowerId: string) {
      return axiosInstance.get<GetCreditFacilityFundRequestResponse>(
        `/CreditFacilities/request-fund/organisation/${borrowerId}`,
      )
    },

    acceptOrDeclineCreditFacilityUpdateRequests(payload: {
      borrowerId: string
      status: AcceptOrDeclineCreditFacilityUpdateRequestPayload
    }) {
      const { borrowerId, status } = payload
      return axiosInstance.put(
        `/CreditFacilities/UpdateRequests/${borrowerId}`,
        { ...status },
      )
    },
  }
}
