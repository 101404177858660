<script setup lang="ts">
import type { NuxtError } from '#app'
import { Button } from '@/components/ui/button'
import SadFaceSvg from '@/assets/svgs/sad-face.svg'
import Cloud1Svg from '@/assets/svgs/cloud1.svg'
import Cloud2Svg from '@/assets/svgs/cloud2.svg'
import Cloud3Svg from '@/assets/svgs/cloud3.svg'
interface Props {
  error: NuxtError
}
const props = defineProps<Props>()
const { $event } = useNuxtApp()

watch(
  () => props.error,
  (newVal) => {
    $event('error:report', newVal)
  },
)
</script>

<template>
  <div class="flex min-h-[100dvh] items-center justify-center p-4">
    <div class="max-w-[40rem] text-primary">
      <div class="relative flex items-center justify-center">
        <img :src="SadFaceSvg" class="max-sm:h-48" />

        <div class="hidden md:block">
          <img :src="Cloud1Svg" alt="" class="absolute -right-6 top-0" />
          <img
            :src="Cloud2Svg"
            class="absolute -top-[5rem] left-1/2 -translate-x-1/2"
          />
          <img :src="Cloud3Svg" alt="" class="absolute left-8 top-0" />
        </div>
      </div>

      <div class="mt-[2.375rem] text-center">
        <div class="space-y-1">
          <h3 class="text-2xl font-bold">
            Oh no! It seems like we might have taken a wrong turn.
          </h3>
          <p class="leading-7">
            We are currently resolving the issues you are experiencing. If this
            persists, please reach out to us on hello@lenkie.com. While you are
            here, please take a look at the video resources we've created to
            help you become a pro at managing your bills.
          </p>
        </div>
        <div class="mt-[1.625rem]">
          <Button
            as="a"
            target="_blank"
            href="https://www.youtube.com/playlist?list=PLpNJ6ZGC0KRqCTh87tcLMA60u6guDPPZn"
            class="mx-auto inline-flex"
            >Go to video resources</Button
          >
        </div>
      </div>
    </div>
  </div>
</template>
