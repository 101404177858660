import type { AxiosInstance } from 'axios'
import type {
  CreatePayrollRecordPayload,
  DeletePayrollRecordPayload,
  MakePayrollPaymentPayloadWithCardPayPayload,
  MakePayrollPaymentPayloadWithOpenBankingPayload,
  UpdatePayrollRecordPayload,
  ExportPayrollRecordsPayload,
  GetPayrollTemplateFilePayload,
} from '~/types/apiPayload/payroll.payload'
import type {
  GetPayrollRecordsResponse,
  ImportPayrollFileResponse,
  MakePayrollPaymentResponse,
} from '~/types/apiResponse/payroll.response'

export const payroll = (axiosInstance: AxiosInstance) => {
  return {
    createPayrollRecord(payload: CreatePayrollRecordPayload) {
      const { organisationId, ...rest } = payload
      return axiosInstance.post(
        `/payroll/organisation/${organisationId}/add`,
        rest,
      )
    },
    updatePayrollRecord(payload: UpdatePayrollRecordPayload) {
      const { organisationId, billId, ...rest } = payload
      return axiosInstance.patch(
        `/payroll/organisation/${organisationId}/update/${billId}`,
        { ...rest },
      )
    },
    archivePayrollRecord(payload: DeletePayrollRecordPayload) {
      const { organisationId, billId } = payload
      return axiosInstance.delete(
        `/payroll/organisation/${organisationId}/archive/${billId}`,
      )
    },
    getPayrollRecords(organisationId: string, isPaid: boolean) {
      return axiosInstance.get<GetPayrollRecordsResponse>(
        `/payroll/organisation/${organisationId}`,
        {
          params: {
            isPaid,
          },
        },
      )
    },
    getPayrollTemplateFile(payload: GetPayrollTemplateFilePayload) {
      const { fileType, organisationId, usePreviousData } = payload
      return axiosInstance.get('/payroll/template', {
        params: {
          fileType,
          usePreviousData: !!usePreviousData,
          organisationId,
        },
        responseType: 'blob',
      })
    },

    importPayrollRecords(payload: { organisationId: string; file: File }) {
      const { organisationId, file } = payload
      const formData = new FormData()
      formData.append('payroll', file)

      return axiosInstance.post<ImportPayrollFileResponse>(
        `payroll/organisation/${organisationId}/import`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      )
    },
    makePayrollPayment(
      payload:
        | MakePayrollPaymentPayloadWithOpenBankingPayload
        | MakePayrollPaymentPayloadWithCardPayPayload,
    ) {
      return axiosInstance.post<MakePayrollPaymentResponse>(
        '/payroll/make-payment',
        payload,
      )
    },
    exportPayrollRecords(payload: ExportPayrollRecordsPayload) {
      const { organisationId, ...rest } = payload
      return axiosInstance.get(
        `/payroll/organisation/${organisationId}/export`,
        { params: { ...rest }, responseType: 'blob' },
      )
    },
  }
}
