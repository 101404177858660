import type { AxiosInstance } from 'axios'
import type { GetBillPaymentPricingDetailsPayload } from '~/types/apiPayload/billPaymentLoans.payload'
import type { GetBillPaymentPricingDetailsResponse } from '~/types/apiResponse/billPaymentLoans.response'

export const billPaymentLoans = (axiosInstance: AxiosInstance) => {
  return {
    getBillPaymentPricingDetails(payload: GetBillPaymentPricingDetailsPayload) {
      return axiosInstance.get<GetBillPaymentPricingDetailsResponse>(
        `/billPaymentLoans/Pricing`,
        {
          params: { ...payload },
        },
      )
    },
  }
}
