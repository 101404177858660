import type { AxiosInstance } from 'axios'
import type {
  Send2FAPayload,
  Validate2FAPayload,
} from '~/types/apiPayload/twoFactorAuthenticator.payload'

export const twoFactorAuthenticator = (axiosInstance: AxiosInstance) => {
  return {
    send2FA(payload: Send2FAPayload) {
      return axiosInstance.post(`/twoFactorAuthenticator/send-2fa`, payload)
    },

    validate2fa(payload: Validate2FAPayload) {
      return axiosInstance.post(`/twoFactorAuthenticator/validate-2fa`, payload)
    },
  }
}
