import type { AxiosInstance } from 'axios'
import type { CreateAccountForAccountingReconciliationPayload } from '~/types/apiPayload/bankAccounts.payload'
import type { IGetPreviouslyUsedBankAccountsResponse } from '~/types/apiResponse/bankAccounts.response'

export const bankAccounts = (axiosInstance: AxiosInstance) => {
  return {
    getPreviouslyUsedBankAccounts(organisationId: string) {
      return axiosInstance.get<IGetPreviouslyUsedBankAccountsResponse>(
        `/openBanking/bankAccounts/${organisationId}`,
      )
    },

    createAccountForAccountingReconciliation(
      organisationId: string,
      payload: CreateAccountForAccountingReconciliationPayload,
    ) {
      return axiosInstance.post(`/openBanking/bankAccounts/${organisationId}`, {
        ...payload,
      })
    },
  }
}
