import type { AxiosInstance } from 'axios'
import type { IOrganisation } from '~/services'
import type { GetCollectionOfOrganizationPayload } from '~/types/apiPayload/organisations.payload'
import type { GetCollectionOfOrganizationResponse } from '~/types/apiResponse/organisations.response'

export const organisations = (axiosInstance: AxiosInstance) => {
  return {
    getOrganisations(params: GetCollectionOfOrganizationPayload) {
      return axiosInstance.get<GetCollectionOfOrganizationResponse>(
        '/organisations',
        { params: { ...params } },
      )
    },

    getSpecificOrganisation(organisationId: string) {
      return axiosInstance.get<IOrganisation>(
        `/Organisations/${organisationId}`,
      )
    },
  }
}
