import bankingApi from '~/network/banking'
import gatewayApi from '~/network/composition'
import coreApi from '~/network/core'
import goCardLessApi from '~/network/go-cardless'
import ocrApi from '~/network/ocr'
import { underwriting } from '~/network/underwriting'
import useApiService from '~/services/axios-config'

export default defineNuxtPlugin(() => {
  const configs = useApiService()

  const api = {
    banking: bankingApi(configs.lenkieBankingApi),
    core: coreApi(configs.coreApiAxiosInstance),
    ocr: ocrApi(configs.ocrApiInstance),
    composition: gatewayApi(configs.compositionApiAxiosInstance),
    goCardLess: goCardLessApi(configs.goCardLessApiInstance),
    underwriting: underwriting(configs.underwritingApiAxiosInstance),
  }

  return {
    provide: {
      ...configs,
      api,
    },
  }
})
