import type { AxiosInstance } from 'axios'
import { me } from './me'
import { onboarding } from './onboarding'

export default function gatewayApi(axiosInstance: AxiosInstance) {
  return {
    me: me(axiosInstance),
    onboarding: onboarding(axiosInstance),
  }
}
