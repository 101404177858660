import type { AxiosInstance } from 'axios'
import type {
  GetMeResponse,
  GetMeResponseV2,
} from '~/types/apiResponse/me.response'

export const me = (axiosInstance: AxiosInstance) => {
  return {
    getMe() {
      return axiosInstance.get<GetMeResponse>(`/Me`)
    },
    getMeV2() {
      return axiosInstance.get<GetMeResponseV2>(`/Me/v2`)
    },
  }
}
