import type { AxiosInstance } from 'axios'
import type { AccountingValidateBillPayload } from '~/types/apiPayload/accounting.payload'
import type { GetAccountingDetailsForVendorFromBillResponse } from '~/types/apiResponse/accounting.response'

export const accounting = (axiosInstance: AxiosInstance) => {
  return {
    validateBill(payload: AccountingValidateBillPayload) {
      return axiosInstance.post('/accounting/validate-bill', { ...payload })
    },
    getAccountingDetailsForVendorFromBill(billId: string) {
      return axiosInstance.get<GetAccountingDetailsForVendorFromBillResponse>(
        `/accounting/vendor-from-bill/${billId}`,
      )
    },
  }
}
