import type { AxiosInstance } from 'axios'
import type { OCRType } from '~/shared/interfaces'

export const uploads = (axiosInstance: AxiosInstance) => {
  return {
    parseInvoiceUsingOCR(params: { organisation_id: string; invoice: File }) {
      const formData = new FormData()
      formData.append('invoice', params.invoice)

      return axiosInstance.post<OCRType>(
        `/invoices/parsing/${params.organisation_id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
    },
  }
}
