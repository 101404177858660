<script setup lang="ts">
import { ToastViewport, type ToastViewportProps } from 'radix-vue'
import { cn } from '@/lib/utils'

const props = defineProps<ToastViewportProps & { class?: string }>()
</script>

<template>
  <ToastViewport
    v-bind="props"
    :class="
      cn(
        'fixed top-0 z-[999999999999] flex max-h-screen w-full flex-col-reverse p-4 sm:left-1/2 sm:top-0 sm:-translate-x-1/2 sm:flex-col md:max-w-[420px]',
        props.class,
      )
    "
  />
</template>
