import mixpanel, { type OverridedMixpanel } from 'mixpanel-browser'
import { useAuthStore } from '~/stores/auth'

const useMixpanel = () => {
  const config = useRuntimeConfig()
  const { MIXPANEL_TOKEN, MIXPANEL_API, APP_ENV } = config.public

  const mixPanelInstance = ref<OverridedMixpanel | null>(null)
  if (APP_ENV !== 'production' && APP_ENV !== 'staging') return ref(null)

  const { isLoggedIn } = storeToRefs(useAuthStore())
  const { profile } = storeToRefs(useProfileStore())

  watch(
    () => [isLoggedIn, profile] as const,
    (newVal) => {
      const [loggedIn, user] = newVal
      if (!loggedIn.value) return
      if (!user.value) return

      mixPanelInstance.value = mixpanel
      mixPanelInstance.value.init(MIXPANEL_TOKEN, { api_host: MIXPANEL_API })

      const firstName = user.value?.given_name || ''
      const lastName = user.value?.family_name || ''
      const fullName = `${firstName} ${lastName}`
      const person_id = user.value?.id || ''
      const phone_number = user.value?.work_phone_number || ''
      const email = user.value?.work_email_address || ''

      mixPanelInstance.value.identify(person_id)
      mixPanelInstance.value.people.set({
        $name: fullName,
        $phone: phone_number,
        $first_name: firstName,
        $last_name: lastName,
        $email: email,
      })
    },
    { immediate: true, deep: true },
  )

  onBeforeUnmount(() => {
    if (mixPanelInstance.value) {
      mixPanelInstance.value.reset()
    }
  })

  return mixPanelInstance
}

export default useMixpanel
