import type { AxiosInstance } from 'axios'
import type {
  GetClientSecretForCardPaymentSetupResponse,
  GetStripeCardsResponse,
} from '~/types/apiResponse/cards.response'

export const cards = (axiosInstance: AxiosInstance) => {
  return {
    getClientSecretForCardPaymentSetup(organisationId: string) {
      return axiosInstance.get<GetClientSecretForCardPaymentSetupResponse>(
        `/Cards/Stripe/SetupIntentSecrets/${organisationId}`,
      )
    },
    getStripeCards(organisationId: string) {
      return axiosInstance.get<GetStripeCardsResponse>(
        `/cards/Stripe/${organisationId}`,
      )
    },
  }
}
