import type { AxiosInstance } from 'axios'
import type { IFundingRequest } from '~/types/apiPayload/requestFunding.payload'

export const additionalFunding = (axiosInstance: AxiosInstance) => {
  return {
    requestForAdditionFunding(payload: IFundingRequest) {
      return axiosInstance.post('/CreditFacilities/request-fund', {
        ...payload,
      })
    },
  }
}
