export default defineNuxtPlugin({
  name: 'error-handler',
  dependsOn: ['eventBus-plugin', 'bugsnag-plugin'],
  setup(nuxtApp) {
    const { $event } = useNuxtApp()
    nuxtApp.vueApp.config.errorHandler = (error) => {
      $event('error:report', error as Error)
      // eslint-disable-next-line no-console
      console.error(error)
    }
  },
})
