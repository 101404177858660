import type { AxiosInstance } from 'axios'
import type { AssignUserRolePayload } from '~/types/apiPayload/roles.payload'
// import type { GetOrganisationProductsResponse } from '~/types/apiResponse/products.response'
import type {
  GetRolesResponse,
  GetUserRoleForOrganizationResponse,
} from '~/types/apiResponse/roles.response'

export const roles = (axiosInstance: AxiosInstance) => {
  return {
    getUserRoleForOrganization(organisationId: string) {
      return axiosInstance.get<GetUserRoleForOrganizationResponse>(
        `/roles/${organisationId}`,
      )
    },

    assignRole(payload: AssignUserRolePayload) {
      const { organisation_id, ...rest } = payload
      return axiosInstance.post(`/roles/${organisation_id}/assign`, rest)
    },

    getRoles() {
      return axiosInstance.get<GetRolesResponse>('/roles')
    },
  }
}
