import type { AxiosInstance } from 'axios'
import type { GetInteractionCountOfAFeatureByOrganizationResponse } from '~/types/apiResponse/featureFlag.response'

export const featureFlag = (axiosInstance: AxiosInstance) => {
  return {
    getInteractionCountOfAFeatureByOrganization(
      organisationId: string,
      featureCode: string,
    ) {
      return axiosInstance.get<GetInteractionCountOfAFeatureByOrganizationResponse>(
        `/featureFlag/interactions`,
        { params: { orgId: organisationId, featureCode } },
      )
    },

    checkIfOrgHasAccessToFeature(organisationId: string, featureCode: string) {
      return axiosInstance.get<{ is_success: boolean; result: boolean }>(
        '/featureFlag/access',
        {
          params: { orgId: organisationId, featureCode },
        },
      )
    },
  }
}
