import type { AxiosInstance } from 'axios'
import { accounting } from './accounting'
import { balances } from './balances'
import { bankAccountValidations } from './bankAccountValidations'
import { bankAccounts } from './bankAccounts'
import { beneficiaries } from './beneficiaries'
import { bills } from './bills'
import { cardPayments } from './cardPayments'
import { cards } from './cards'
import { creditFacilities } from './creditFacilities'
import { fileUpload } from './fileUpload'
import { fx } from './fx'
import { mail } from './mail'
import { payments } from './payment'
import { payroll } from './payroll'
import { repayments } from './repayments'
import { additionalFunding } from './requestFunding'
import { billPaymentLoans } from './billPaymentLoans'
import { settings } from './settings'
import { confirmationOfPayee } from './confirmationOfPayee'

export default function bankingApi(axios: AxiosInstance) {
  return {
    accounting: accounting(axios),
    bankAccountValidations: bankAccountValidations(axios),
    bankAccounts: bankAccounts(axios),
    beneficiaries: beneficiaries(axios),
    bills: bills(axios),
    cardPayments: cardPayments(axios),
    cards: cards(axios),
    creditFacilities: creditFacilities(axios),
    fx: fx(axios),
    mail: mail(axios),
    payments: payments(axios),
    repayments: repayments(axios),
    requestForFunding: additionalFunding(axios),
    balances: balances(axios),
    payroll: payroll(axios),
    fileUpload: fileUpload(axios),
    billPaymentLoans: billPaymentLoans(axios),
    settings: settings(axios),
    confirmationOfPayee: confirmationOfPayee(axios),
  }
}
