import type { AxiosInstance } from 'axios'
import type { VerifyDirectorsPayload } from '~/types/apiPayload/directors.payload'

export const directors = (axiosInstance: AxiosInstance) => {
  return {
    verifyDirectors(payload: VerifyDirectorsPayload) {
      return axiosInstance.put(`/Directors/Verification`, { ...payload })
    },
  }
}
