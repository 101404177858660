import type { AxiosInstance } from 'axios'
import type { IGetMailResponse } from '~/types/apiResponse/mail.response'

export const mail = (axiosInstance: AxiosInstance) => {
  return {
    getMailBox(organisationId: string) {
      return axiosInstance.get<IGetMailResponse>(`/Mail/${organisationId}`)
    },
  }
}
