export default {
  INACTIVITY_TIME_ALLOWED: 5 * 60 * 1000,
  ACCOUNTING_SYNC_INTERVAL_TIME: 2 * 60 * 1000,
  CARD_PAYMENT_FEES: 0.029,
} as const

export const LOCAL_STORAGE_KEYS = {
  SEEN_VENDOR_CREATE_INFO_COUNT: 'lk_svcic',
  LAST_ACTIVE_TIMESTAMP: 'lk_lats',
  LAST_SELECTED_ORGANISATION_ID: 'orgId',
}

export const SESSION_STORAGE_KEYS = {
  SESSION_CODE: 'session_code',
  CURRENT_PIN: 'wax-p',
}
