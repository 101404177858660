import { User, type SigninRedirectArgs } from 'oidc-client-ts'
import { useAuthStore } from '@/stores/auth'
import { useAuthService } from '@/services/auth'

const authFlowRoutes = ['/auth', '/silent-refresh', '/logout', '/callback']
const excludedRoutes = [routes.quickBooksDisconnectedPage]

export default defineNuxtRouteMiddleware(async (to, _) => {
  if (excludedRoutes.includes(to.path)) return
  const authStore = useAuthStore()
  const authService = useAuthService()
  const user = (await authService.getUser()) as User

  if (!user && !authFlowRoutes.includes(to.path)) {
    const { platformId, brokerId } = to.query
    sessionStorage.setItem('redirectTo', to.fullPath.toString())
    const redirectParams: SigninRedirectArgs | undefined = {
      extraQueryParams: {
        ...(platformId ? { platformId: platformId.toString() } : {}),
        ...(brokerId ? { brokerId: brokerId.toString() } : {}),
      },
    }

    authService.signInRedirect(redirectParams)
  } else {
    authStore.setUpUserCredentials(user)
  }
})
